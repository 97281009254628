<template>
  <div class="auth" :class="{ 'show-modal': animateModal }" @click.stop="handleOutsideClick">
    <div class="auth__inner">
      <span class="auth__close">
        <img @click.stop="close()" src="@/assets/images/x-close.svg" alt="" />
      </span>
      <div class="auth__content" v-if="statePages === 1">
        <ul class="auth__tabs">
          <li
            :class="{ active: item.id === activeTab }"
            v-for="(item, i) in tabs"
            :key="i"
            @click.stop="changeTab(item.id)"
            class="gray-500 text-md semibold"
          >
            {{ item.title }}
          </li>
        </ul>
        <form @submit.prevent="Login()" class="auth__form" v-if="activeTab === 1">
          <countries v-model="loginForm.country_id" />
          <div class="text-field">
            <label class="gray-700 text-sm medium" for="">{{ $t('phoneNumber') }}</label>
            <input
              v-model="loginForm.phone_number"
              type="text"
              :placeholder="placeholder(loginForm.country_id)"
              required
              v-maska
              data-maska="+7(###)###-##-##"
            />
          </div>
          <div class="text-field text-field__eye">
            <label class="gray-700 text-sm medium" for="">{{ $t('password') }}</label>
            <input
              v-model="loginForm.password"
              :type="showPassword ? 'text' : 'password'"
              :placeholder="$t('enterPassword')"
              required
            />
            <span class="eye" @click.stop="showPasswordFunc()">
              <img v-if="showPassword" src="@/assets/images/eye.svg" alt="" />
              <img v-else src="@/assets/images/eye-off.svg" alt="" />
            </span>
          </div>
          <span class="error-txt" v-if="errorMsg && errorMsg.login">
            {{ errorMsg.login }}
          </span>
          <p
            @click.stop="redirectState(2)"
            class="gray-blue-700 text-md semibold cursor-pointer"
          >
            {{ $t('forgotPassword') }}
          </p>
          <button class="button text-md semibold full" type="submit">{{ $t('login') }}</button>
          <p class="gray-400 text-md regular">
            {{ $t('agreePolicy') }}
          </p>
        </form>
        <form @submit.prevent="Registration()" class="auth__form" v-if="activeTab === 2">
          <countries v-model="registerForm.country_id" />
          <div class="text-field">
            <label class="gray-700 text-sm medium" for="">{{ $t('phoneNumber') }}</label>
            <input
              v-model="registerForm.phone_number"
              type="text"
              :placeholder="placeholder(registerForm.country_id)"
              required
              v-maska
              data-maska="+7(###)###-##-##"
            />
          </div>
          <span class="error-txt" v-if="errorMsg && errorMsg.register">
            {{ errorMsg.register }}
          </span>
          <button class="button text-md semibold full" type="submit">{{ $t('getCode') }}</button>
          <p class="gray-400 text-md regular">
            {{ $t('agreePolicy') }}
          </p>
        </form>
      </div>
      <div class="auth__content auth__state-second" v-else-if="statePages === 2">
        <div class="back" @click.stop="redirectState(1)">
          <img src="@/assets/images/arrow-narrow-left.svg" alt="" />
        </div>
        <p class="gray-900 text-xl semibold">{{ $t('forgotPassword') }}</p>
        <p class="gray-900 text-md medium">{{ $t('phoneNumber') }}</p>
        <form @submit.prevent="Forgot()" class="auth__form">
          <countries v-model="forgotForm.country_id" />
          <div class="text-field">
            <label class="gray-700 text-sm medium" for="">{{ $t('phoneNumber') }}</label>
            <input
              v-model="forgotForm.phone_number"
              type="text"
              :placeholder="placeholder(forgotForm.country_id)"
              required
              v-maska
              data-maska="+7(###)###-##-##"
            />
          </div>
          <button class="button text-md semibold full" type="submit">{{ $t('getCode') }}</button>
        </form>
      </div>
      <div class="auth__content auth__state-second" v-else-if="statePages === 3">
        <div class="back" @click.stop="redirectState(2)">
          <img src="@/assets/images/arrow-narrow-left.svg" alt="" />
        </div>
        <p class="gray-900 text-xl semibold">{{ $t('confirmPhoneNumber') }}</p>
        <p class="gray-900 text-md medium mt-8" v-if="forgotForm.phone_number || registerForm.phone_number">
          {{ activeTab == 1 ? forgotForm.phone_number : registerForm.phone_number }}
        </p>
        <form @submit.prevent="Confirm()" class="auth__form">
          <div class="text-field auth__state-second-input-field">
            <label class="gray-700 text-sm medium" for="">{{ $t('smsCode') }}</label>
            <div class="text-field auth__state-second-input-box">
              <input
                v-for="index in 4"
                :key="index"
                v-model="code[index - 1]"
                @input="handleInput(index - 1, $event)"
                :ref="(el) => (inputRefs[index - 1] = el)"
                type="text"
                class="auth__state-second-input"
                placeholder="0"
                maxlength="1"
              />
            </div>
          </div>
          <span class="error-txt" v-if="errorMsg && errorMsg.confirm">
            {{ errorMsg.confirm }}
          </span>
          <button class="button text-md semibold full" type="submit">
            {{ $t('confirmNumber') }}
          </button>
        </form>
      </div>
      <div class="auth__content auth__state-second" v-else-if="statePages === 4">
        <div class="back" @click.stop="redirectState(1)">
          <img src="@/assets/images/arrow-narrow-left.svg" alt="" />
        </div>
        <p class="gray-900 text-xl semibold">{{ $t('register') }}</p>
        <form @submit.prevent="Password()" class="auth__form">
          <div class="text-field">
            <label class="gray-700 text-sm medium" for="">{{ $t('firstName') }}</label>
            <input
              v-model="register.first_name"
              :placeholder="$t('enterFirstName')"
              type="text"
              required
            />

          </div>
          <div class="text-field">
            <label class="gray-700 text-sm medium" for="">{{ $t('lastName') }}</label>
            <input
              v-model="register.last_name"
              :placeholder="$t('enterLastName')"
              type="text"
              required
            />
          </div>
          <div class="text-field">
            <label class="gray-700 text-sm medium" for="">{{ $t('email') }}</label>
            <input
              v-model="register.email"
              :placeholder="$t('enterEmail')"
              type="text"
              required
            />
          </div>
          <div class="text-field">
            <label class="gray-700 text-sm medium" for="">Partner ID</label>
            <input
              v-model="register.partner_id"
              :placeholder="$t('enterPartner')"
              type="text"
            />
          </div>
          <div class="text-field text-field__eye">
            <label class="gray-700 text-sm medium" for="">{{ $t('createPassword') }}</label>
            <input
              v-model="register.password"
              :placeholder="$t('enterPassword')"
              type="password"
              required
            />
          </div>
          <div class="text-field text-field__eye">
            <label class="gray-700 text-sm medium" for="">{{ $t('repeatPassword') }}</label>
            <input
              v-model="register.password_confirmation"
              :placeholder="$t('enterPassword')"
              type="password"
              required
            />
          </div>
          <span class="error-txt" v-if="errorMsg && errorMsg.password">
            {{ errorMsg.password }}
          </span>
          <button class="button text-md semibold full" type="submit">
            {{ $t('register') }}
          </button>
        </form>
      </div>
      <div class="auth__content" v-if="statePages === 5">
        <p class="gray-900 text-xl semibold">{{ $t('createPassword') }}</p>
        <form @submit.prevent="ChangePassword()" class="auth__form">
          <div class="text-field text-field__eye">
            <label class="gray-700 text-sm medium" for="">{{ $t('createPassword') }}</label>
            <input
              v-model="ForgetPasswordReset.password"
              :type="showPassword_2.password ? 'text' : 'password'"
              :placeholder="$t('enterPassword')"
              required
            />
            <span class="eye" @click.stop="showForgetPasswordFunc('password')">
              <img v-if="showPassword_2.password" src="@/assets/images/eye.svg" alt="" />
              <img v-else src="@/assets/images/eye-off.svg" alt="" />
            </span>
          </div>
          <div class="text-field text-field__eye">
            <label class="gray-700 text-sm medium" for="">{{ $t('repeatPassword') }}</label>
            <input
              v-model="ForgetPasswordReset.password_confirmation"
              :type="showPassword_2.password_confirmation ? 'text' : 'password'"
              :placeholder="$t('enterPassword')"
              required
            />
            <span class="eye" @click.stop="showForgetPasswordFunc('password_confirmation')">
              <img v-if="showPassword_2.password_confirmation" src="@/assets/images/eye.svg" alt="" />
              <img v-else src="@/assets/images/eye-off.svg" alt="" />
            </span>
          </div>
          <button class="button text-md semibold full" type="submit">{{ $t('updatePassword') }}</button>
        </form>
      </div>
      <div class="auth__content auth__state-second" v-else-if="statePages === 6">
        <div class="auth__content__title">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <rect width="28" height="28" rx="14" fill="#17B26A"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z" fill="white"/>
          </svg>
          <p class="gray-900 text-xl semibold">{{ $t('passwordChanged') }}</p>
        </div>
        <button class="button text-md semibold full" @click.stop="close()">
          {{ $t('close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, computed, watch, nextTick } from "vue";
import countries from "@/components/global/Countries.vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { vMaska } from "maska";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps();

const store = useStore();

const router = useRouter();

const route = useRoute();

const emits = defineEmits(["closeModal"]);

const animateModal = ref(false);

const code = ref(["", "", "", ""]);
const inputRefs = ref([]);

const loginForm = ref({
  country_id: 1,
  phone_number: "",
  password: "",
});

const showPassword = ref(false);

const countriesList = computed(() => store.state.global.countries);

const registerForm = ref({
  country_id: 1,
  phone_number: "",
});

const forgotForm = ref({
  country_id: 1,
  phone_number: "",
});

const register = ref({
  password: "",
  password_confirmation: "",
  first_name: "",
  last_name: "",
  email: "",
  partner_id: null,
});

const ForgetPasswordReset = ref({
  password: "",
  password_confirmation: "",
});

const errorMsg = ref({
  login: "",
  register: "",
  confirm: "",
  password: "",
});

const errorRegisterMsg = ref({
  password: "",
  password_confirmation: "",
  first_name: "",
  last_name: "",
  email: "",
  partner_id: null,
});

const showPassword_2 = ref({
  password: false,
  password_confirmation: false,
});

const tabs = ref([
  {
    id: 1,
    title: t('login'),
  },
  {
    id: 2,
    title: t('registration'),
  },
]);

const statePages = ref(1);
// 1 - Авторизация/Регистрация
// 2 - Забыли пароль
// 3 - Подтвердите номер

const activeTab = ref(1);

const changeTab = (event) => {
  activeTab.value = event;
};

const redirectState = (state) => {
  statePages.value = state;
};

const showPasswordFunc = () => {
  showPassword.value = !showPassword.value;
};

const showForgetPasswordFunc = (type) => {
  if(type=='password'){
    showPassword_2.value.password = !showPassword_2.value.password;
  }else{
    showPassword_2.value.password_confirmation = !showPassword_2.value.password_confirmation;
  }
};

const placeholder = (country_id) => {
  if (country_id) {
    const element = countriesList.value.find((el) => el.id === country_id);
    if (element) {
      return `${element.phone_code}`;
    }
  }

  return "";
};

const handleInput = async (index, event) => {
  const digit = event.target.value.replace(/[^0-9]/g, "");
  if (digit) {
    code.value[index] = digit; // Обновляем модель только цифрами
    await nextTick();
    // Проверяем, не последний ли это инпут, и переключаем фокус на следующий
    if (index < 3) {
      inputRefs.value[index + 1].focus();
    }
  } else {
    // Очищаем значение, если введён нецифровой символ
    code.value[index] = "";
    event.target.value = "";
  }
};

const formatterPhone = (value) => {
  const phone = value.replace(/[^\d+]/g, "");

  return phone;
};

const close = () => {
  animateModal.value = false;

  setTimeout(() => {
    emits("closeModal");
  }, 200);
};

const Registration = async () => {
  await store
    .dispatch("auth/sendRegisterSms", {
      country_id: registerForm.value.country_id,
      phone_number: formatterPhone(registerForm.value.phone_number)
    })
    .then((res) => {
      if (res) {
        statePages.value = 3;
      }
    })
    .catch((err) => {
      if (err && err.response && err.response.data) {
        if (err.response.data.msg) {
          errorMsg.value.register = err.response.data.msg;
        }else if (err.response.data.message){
          errorMsg.value.register = err.response.data.message;
        }
      }
    });
};

const Forgot = async () => {
  await store
    .dispatch("auth/fotgetPasswordSms", {
      phone_number:formatterPhone(forgotForm.value.phone_number)
    })
    .then((res) => {
      if (res) {
        statePages.value = 3;
      }
    })
    .catch((err) => {
      if (err && err.response && err.response.data) {
        if (err.response.data.msg) {
          errorMsg.value.register = err.response.data.msg;
        }else if (err.response.data.message){
          errorMsg.value.register = err.response.data.message;
        }
      }
    });
};

const Login = async () => {
  await store
    .dispatch("auth/login", {
      phone_number: formatterPhone(loginForm.value.phone_number),
      password: loginForm.value.password,
    })
    .then((res) => {
      console.log(res);
      if (res && res.err === false) {
        window.location.replace("/profile");
      }
    })
    .catch((err) => {
      console.log(err);

      if (err && err.response && err.response.data) {
        if (err.response.data.err) {
          errorMsg.value.login = err.response.data.data;
        }
      }
    });
};

const Confirm = async () => {
  const val = code.value.join("");
  const url = activeTab.value == 1 ? 'auth/fotgetPasswordConfirmSms' : 'auth/confirmSms';
  const phoneNumber = activeTab.value == 1 ? forgotForm.value.phone_number : registerForm.value.phone_number;

  await store
    .dispatch(url, {
      phone_number: formatterPhone(phoneNumber),
      code: val,
    })
    .then((res) => {
      if (res) {
        if(activeTab.value == 2){
          statePages.value = 4;
        }
        if(activeTab.value == 1){
          statePages.value = 5;
        }
      }
    })
    .catch((err) => {
      if (err && err.response && err.response.data && err.response.data.data) {
        errorMsg.value.confirm = err.response.data.data;
      }
    });
};

const Password = async () => {
  await store
    .dispatch("auth/register", {
      country_id: registerForm.value.country_id,
      phone_number: formatterPhone(registerForm.value.phone_number),
      password: register.value.password,
      password_confirmation: register.value.password_confirmation,
      first_name: register.value.first_name,
      last_name: register.value.last_name,
      email: register.value.email,
      partner_id: register.value.partner_id
    })
    .then((res) => {
      if (res && res.err === false) {
        window.location.replace("/profile");
      }
    })
    .catch((err) => {
      if (err && err.response && err.response.data && err.response.data.message) {
        errorMsg.value.password = err.response.data.message;
      }
    });
};

const ChangePassword = async () => {
  await store
    .dispatch("auth/fotgetChangePassword", {
      phone_number: formatterPhone(forgotForm.value.phone_number),
      password: ForgetPasswordReset.value.password,
      password_confirmation: ForgetPasswordReset.value.password_confirmation,
    })
    .then((res) => {
      if (res) {
        statePages.value = 6;
      }
    })
    .catch((err) => {
      errorMsg.value.password = err.response.data.message;
    });
};

const handleOutsideClick = (event) => {
  const authInner = event.target.closest('.auth__inner');
  if (!authInner) {
    close();
  }
}

onMounted(() => {
  if(route.query && route.query.token){
    activeTab.value = 2;
  }

  setTimeout(() => {
    animateModal.value = true;
  }, 80);
});
</script>

<style scoped>
.auth__content__title{
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;
  }
</style>
